<template>
  <div>
    <van-cell-group>
      <van-cell>
        <template #title>
          <div class="blue-box"></div>
          <span class="custom-title">基本信息</span>
        </template>
      </van-cell>
      <van-field readonly v-model="dataForm.communityName" label="所属社区" placeholder="未知" input-align="right"/>
      <van-field readonly v-model="dataForm.subareaName" label="所属小区" placeholder="未知" input-align="right"/>
      <van-field readonly v-model="dataForm.buildingName" label="所属楼幢" placeholder="未知" input-align="right"/>
      <van-field readonly v-model="dataForm.unitName" label="所属单元" placeholder="未知" input-align="right"/>
      <van-field readonly v-model="dataForm.fullName" label="房屋名称" placeholder="未知" input-align="right"/>
<!--      <van-field readonly v-model="dataForm.fullName" label="详细位置" placeholder="未知" input-align="right"/>-->
      <van-field readonly v-model="dataForm.areas" label="房屋面积 m²" placeholder="未知" input-align="right"/>
      <van-field readonly v-model="dataForm.floor" label="所在楼层" placeholder="未知" input-align="right"/>
      <van-field readonly v-model="dataForm.doorModelStr" label="户型" placeholder="未知" input-align="right"/>
    </van-cell-group>

    <van-cell-group class="info-second" v-if="!!dataForm.gridId">
      <van-cell>
        <template #title>
          <div class="blue-box"></div>
          <span>网格信息</span>
          <div style="color:#387FF5;float:right" @click="firstVisible=!firstVisible"><van-icon :name="firstVisible?'arrow-up':'arrow-down'"></van-icon></div>
        </template>
      </van-cell>
      <div v-show="firstVisible">
        <van-cell title="所属网格">
          <van-field clearable clear-trigger="always" readonly v-model="dataForm.gridName" placeholder="请输入" style="text-align: right"/>
        </van-cell>
        <van-cell title="网格员">
          <van-field clearable clear-trigger="always" readonly v-model="dataForm.gridUser" placeholder="请输入" style="text-align: right"/>
        </van-cell>
      </div>
    </van-cell-group>

    <van-cell-group class="info-second">
      <van-cell>
        <template #title>
          <div class="blue-box"></div>
          <span class="custom-title">附属信息</span>
          <div style="color:#387FF5;float:right" @click="infoVisible=!infoVisible"><van-icon :name="infoVisible?'arrow-up':'arrow-down'"></van-icon></div>
        </template>
      </van-cell>
      <div v-show="infoVisible">
        <van-field readonly v-model="dataForm.useTypeStr" label="房屋属性" placeholder="未知" input-align="right"/>
        <van-field readonly v-model="dataForm.houseCard" label="不动产权证号" placeholder="未知" input-align="right"/>
        <van-field readonly v-model="dataForm.collectiveOwnership" label="共有情况" placeholder="未知" input-align="right"/>
        <van-field readonly v-model="dataForm.rentalRoomNum" label="出租间数" placeholder="未知" input-align="right"/>
        <van-field readonly v-model="dataForm.residentsLimit" label="限住人数" placeholder="未知" input-align="right"/>
        <van-field readonly :value="dataForm.manyHomeowners?'是':'否'" label="是否分户" placeholder="未知" input-align="right"/>
        <van-field readonly v-model="dataForm.code" label="统一地址编码" placeholder="未知" input-align="right"/>
        <van-field readonly v-model="dataForm.remark" placeholder="备注" type="textarea" autosize input-align="left" class="left"/>
      </div>

    </van-cell-group>

    <van-cell-group class="info-second list-info">
      <van-cell>
        <template #title>
          <div class="blue-box"></div>
          <span class="custom-title">走访情况</span>
          <div style="float:right;color:#999;font-size:12px;display:flex;align-items:center" @click="goVisit">更多<van-icon name="arrow"></van-icon></div>
        </template>
      </van-cell>
      <div class="cont ">
        <van-list finished-text="没有更多了" v-if="visitList.length > 0" offset="10">
          <div class="list-item" v-for="item in visitList" :key="item.id" @click="goVisitInfo(item.id, item.visitBody, item.fullLocation)">
            <div class="title">
              <div class="title-left">
                {{item.createUserName + ' | ' + item.createTime}}
              </div>
              <div class="title-right">
                {{item.visitTypeStr}}
              </div>
            </div>
            <div class="content">
              <van-image :src="item.filesPath.length!==0?item.filesPath[0].url:require('@/assets/img/flower.png')" class="content-img">
                <template slot="error">
                  <img :src="require('@/assets/img/flower.png')" alt="" class="content-img">
                </template>
                <template slot="loading">
                  <img :src="require('@/assets/img/flower.png')" alt="" class="content-img">
                </template>
              </van-image>
              <div class="content-text">
                <div class="top-text">{{item.visitBody == 2 ? item.placeStr : item.fullLocation == null ? '无房户' : item.fullLocation}}</div>
                <div class="bottom-text">{{item.visitDes}}</div>
              </div>
              <van-icon name="arrow" color="#666" class="content-icon"/>
            </div>
          </div>
        </van-list>
        <van-image v-else :src="require('@/assets/img/empty.png')" style="margin-left:10%;width:80%;"></van-image>
      </div>
    </van-cell-group>
    <!-- <van-cell-group class="info-second list-info">
      <van-cell>
        <template #title>
          <div class="blue-box"></div>
          <span class="custom-title">报修情况</span>
          <div style="float:right;color:#999;font-size:12px;display:flex;align-items:center" @click="goRepair">更多<van-icon name="arrow"></van-icon></div>
        </template>
      </van-cell>
      <div class="cont ">
        <van-list finished-text="没有更多了" v-if="repairList.length > 0" offset="10">
          <div class="list-item" v-for="item in repairList" :key="item.id" @click="goRepairInfo(item.id,item.solveStatus)">
            <div class="title">
              <div class="title-left">
                {{item.userName + ' | ' + item.createTime}}
              </div>
              <div class="title-right" :style="{color:item.solveStatus==10?'#FE5E5E':'#3E7EFE'}">
                {{item.solveStatusStr}}
              </div>
            </div>
            <div class="content">
              <van-image :src="item.url||require('@/assets/img/flower.png')" class="content-img">
                <template slot="error">
                  <img :src="require('@/assets/img/flower.png')" alt="" class="content-img">
                </template>
                <template slot="loading">
                  <img :src="require('@/assets/img/flower.png')" alt="" class="content-img">
                </template>
              </van-image>
              <div class="content-text">
                <div class="top-text">{{ item.fullLocation }} ({{ item.repairTypeStr }})</div>
                <div class="bottom-text">{{item.repairDes}}</div>
              </div>
              <van-icon name="arrow" color="#666" class="content-icon"/>
            </div>
          </div>
        </van-list>
        <van-image v-else :src="require('@/assets/img/empty.png')" style="margin-left:10%;width:80%;"></van-image>
      </div>
    </van-cell-group> -->

    <!-- <van-cell-group class="info-second list-info">
      <van-cell>
        <template #title>
          <div class="blue-box"></div>
          <span class="custom-title">问题情况</span>
          <div style="float:right;color:#999;font-size:12px;display:flex;align-items:center" @click="goProblem">更多<van-icon name="arrow"></van-icon></div>
        </template>
      </van-cell>
      <div class="cont ">
        <van-list finished-text="没有更多了" v-if="problemList.length > 0" offset="10">
          <div class="list-item" v-for="item in problemList" :key="item.id" @click="goProblemInfo(item.id,item.status)">
            <div class="title">
              <div class="title-left">
                {{item.name + ' | ' + item.createDate}}
              </div>
              <div class="title-right" :style="{color:item.solveStatus==10?'#FE5E5E':'#3E7EFE'}">
                {{item.statusStr}}
              </div>
            </div>
            <div class="content">
              <van-image :src="item.url||require('@/assets/img/flower.png')" class="content-img">
                <template slot="error">
                  <img :src="require('@/assets/img/flower.png')" alt="" class="content-img">
                </template>
                <template slot="loading">
                  <img :src="require('@/assets/img/flower.png')" alt="" class="content-img">
                </template>
              </van-image>
              <div class="content-text">
                <div class="top-text">{{ item.houseFullName }} ({{ item.typeStr }})</div>
                <div class="bottom-text">{{item.content}}</div>
              </div>
              <van-icon name="arrow" color="#666" class="content-icon"/>
            </div>
          </div>
        </van-list>
        <van-image v-else :src="require('@/assets/img/empty.png')" style="margin-left:10%;width:80%;"></van-image>
      </div>
    </van-cell-group> -->
    <!-- <van-cell-group class="info-second list-info">
      <van-cell>
        <template #title>
          <div class="blue-box"></div>
          <span class="custom-title">车辆信息</span>
         <div style="float:right;color:#999;font-size:12px;display:flex;align-items:center" @click="goCar">更多<van-icon name="arrow"></van-icon></div>
        </template>
      </van-cell>
      <div class="cont ">
        <van-list finished-text="没有更多了" v-if="carList.length > 0" offset="10">
          <div class="list-item" v-for="item in carList" :key="item.id" @click="goCarInfo(item.id,item.subarea)">
            <div class="title">
              <div class="title-left">
                {{item.userName + ' | ' + item.mobile}}
              </div>
              <div class="title-right">
                {{item.colorStr}}
              </div>
            </div>
            <div class="content">
              <van-image :src="item.url||require('@/assets/img/flower.png')" class="content-img">
                <template slot="error">
                  <img :src="require('@/assets/img/flower.png')" alt="" class="content-img">
                </template>
                <template slot="loading">
                  <img :src="require('@/assets/img/flower.png')" alt="" class="content-img">
                </template>
              </van-image>
              <div class="content-text">
                <div class="top-text">{{ item.brand }} ({{ item.typeStr }})</div>
                <div class="bottom-text">车辆牌照: {{item.carNumber}}</div>
              </div>
              <van-icon name="arrow" color="#666" class="content-icon"/>
            </div>
          </div>
        </van-list>
        <van-image v-else :src="require('@/assets/img/empty.png')" style="margin-left:10%;width:80%;"></van-image>
      </div>
    </van-cell-group> -->

  </div>
</template>

<script>
import topBar from '@/components/topBar/topBar'
import {getImageStream} from '@/utils/index'
import {mapMutations, mapState} from 'vuex'

export default {
  components: {
    topBar
  },
  data() {
    return {
      infoVisible: false,
      firstVisible: true,
      dataForm: {},
      problemList: [],
      repairList: [],
      visitList: [],
      carList: []
    }
  },
  computed: {...mapState(['houseId'])},
  methods: {
    ...mapMutations(['setProblemData', 'setRepairData', 'setVisitId', 'setVisitData', 'setHouseId']),
    init() {
      this.$http({
        url: this.$http.adornUrl('/wxapp/building/house/info'),
        method: 'post',
        params: this.$http.adornParams({
          id: this.houseId
        })
      }).then(({data}) => {
        if (data.code == 0) {
          this.dataForm = data.buildingHouse
        }
        this.$toast.clear()
      }, err => {
        this.$toast.clear()
      })
    },
    // 报修列表
    getRepairList() {
      this.$http({
        url: this.$http.adornUrl('/wxapp/commonApp/repair/info/list'),
        method: 'post',
        params: this.$http.adornParams({
          page: '1',
          limit: '2',
          houseId: this.houseId
        })
      }).then(({data}) => {
        if (data && data.code == 0) {
          data.page.list.forEach(item => {
            if (item.fileDisplayVOs && item.fileDisplayVOs.length != 0) {
              let file = item.fileDisplayVOs[0]
              let url = file.relativeUrl
              if (url) {
                url = getImageStream(url)
                item["url"] = url
              }
            }
          })
          this.repairList = data.page.list
          // 加载状态结束
          this.$toast.clear()
        }
        this.$toast.clear()
      }, err => {
        this.$toast.clear()
      })
    },    // 报修列表
    getCarList() {
      this.$http({
        url: this.$http.adornUrl('/wxapp/building/house/infoCarInformation'),
        method: 'post',
        params: this.$http.adornParams({
          houseId: this.houseId,
          orgId: this.$globalData.userInfo.orgId
        })
      }).then(({data}) => {
        if (data && data.code == 0) {
          this.carList = data.data
          // 加载状态结束
          this.$toast.clear()
        }
        this.$toast.clear()
      }, err => {
        this.$toast.clear()
      })
    },
    // 问题列表
    getProblemList() {
      this.$http({
        url: this.$http.adornUrl('/wxapp/problem/list'),
        method: 'post',
        params: this.$http.adornParams({
          page: '1',
          limit: '2',
          houseId: this.houseId
        })
      }).then(({data}) => {
        if (data && data.code == 0) {
          data.list.list.forEach(item => {
            if (item.problemFiles && item.problemFiles.length !== 0) {
              let file = item.problemFiles[0]
              let url = file.filePath
              if (url) {
                url = getImageStream(url)
                item["url"] = url
              }
            }
          })
          this.problemList = data.list.list
          // 加载状态结束
          this.$toast.clear()
        }
        this.$toast.clear()
      }, err => {
        this.$toast.clear()
      })
    },
    // 走访列表
    getVisitList() {
      this.$http({
        url: this.$http.adornUrl('/wxapp/visit/list'),
        method: 'post',
        params: this.$http.adornParams({
          page: '1',
          limit: '2',
          houseId: this.houseId
        })
      }).then(({data}) => {
        if (data && data.code == 0) {
          this.visitList = data.page.list
          // 加载状态结束
          this.$toast.clear()
        }
        this.$toast.clear()
      }, err => {
        this.$toast.clear()
      })
    },
    // 跳转问题详情
    goProblemInfo(id, status) {
      let data = {'id': id, 'status': status}
      this.$store.commit('setProblemData', data)
      this.$router.push('/problem-info')
    },
    goProblem: function () {
      this.$router.push({path: '/problem', query: {houseId: this.houseId + ''}})
    },
    // 跳转报修详情
    goRepairInfo (id, status) {
      let data = {'id':id,'status':status}
      this.$store.commit('setRepairData', data)
      this.$router.push('/repair-info')
    },
    goRepair() {
      this.$router.push({path: '/repair', query: {houseId: this.houseId + ''}})
    },
    goVisitInfo(id, visitType, location) {
      let type = ''
      if (visitType == 2) {
        type = '3'
      } else {
        type = location ? '1' : '2'
      }
      this.$router.push({path: '/visit-add', query: {id: id, type: type}})
    },
    goVisit(id) {
      this.$router.push({path: '/visit', query: {houseId: this.houseId + ''}})
    },
    goCar(){
      this.$router.push({path: '/car', query: null})
    },
    goCarInfo(id,subarea){
      this.$router.push({path: '/car-add', query: {id:id, subarea: subarea}})
    }
  },
  created() {
    let options = this.$route.query
    if (this.$route.query.adrrType) {
      this.$store.commit("setHouseId", parseInt(options.id));
    }
    this.$nextTick(() => {
      this.init()
    })
    this.getRepairList()
    this.getProblemList()
    this.getVisitList()
    this.getCarList()
  }
}
</script>
<style scoped>
.list-info {
  padding-bottom: 20px !important;
}
.list-item {
  border: 1px solid #eee;
}
.list-item:nth-child(2) {
  margin-top: 20px !important;
}
.left >>> .van-field__control {
  text-align: left !important;
}
</style>
